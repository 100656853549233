import { useToast } from '@chakra-ui/react';
import ZoneModal from 'components/ZoneModal';
import i18n from 'lib/i18n';
import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TOAST_STATUS } from 'utils/constants';
import capitalize from 'lodash.capitalize';
import { tType } from 'types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentCompanyId } from 'lib/auth';
import { getCurrentUser } from 'stores/currentUser';
import {
  createZoneThunk,
  fetchAllZonesThunk,
  fetchZonesThunk,
  getAllZonesByCompany,
  removeAllCompanyZones,
} from 'stores/zones';

const { withTranslation } = i18n;

const ZoneCreationContainer = ({ t, isOpen, close }) => {
  const toast = useToast();
  const dispatch = useDispatch();
  const currentCompanyId = getCurrentCompanyId();
  const { isSubTenant } = useSelector(getCurrentUser);
  const { items } = useSelector(getAllZonesByCompany) || [];

  const handleToastMessage = useCallback(
    error => {
      toast({
        title: '',
        description: error
          ? error.message
          : capitalize(t('zones_page.zone_created_successfully')),
        status: error ? TOAST_STATUS.ERROR : TOAST_STATUS.SUCCESS,
        duration: 5000,
        position: 'top',
        isClosable: true,
      });
    },
    [t, toast],
  );

  useEffect(() => {
    dispatch(
      fetchAllZonesThunk({
        companyId: currentCompanyId,
      }),
    );
  }, [currentCompanyId, dispatch]);

  const handleSubmit = useCallback(
    async ({ zoneName, parentZoneId, zoneBillable }) => {
      const params = {
        billable: isSubTenant || zoneBillable,
        name: zoneName,
        parentId: parentZoneId,
        companyId: currentCompanyId,
      };
      const { error } = await dispatch(createZoneThunk({ params }));

      if (!error) {
        await dispatch(removeAllCompanyZones());
        await dispatch(fetchZonesThunk({ companyId: currentCompanyId }));
      }

      handleToastMessage(error);
    },
    [currentCompanyId, dispatch, handleToastMessage, isSubTenant],
  );

  return (
    <ZoneModal
      isOpen={isOpen}
      close={close}
      zones={items}
      isSubTenant={isSubTenant}
      submitData={handleSubmit}
    />
  );
};

ZoneCreationContainer.propTypes = {
  t: tType,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
};

export default withTranslation()(ZoneCreationContainer);
