import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@chakra-ui/react';
import { RESOURCE_STATUS } from 'utils/constants';

const { IDLE, LOADING } = RESOURCE_STATUS;

export const withSpinner = WrappedComponent => {
  const WithSpinner = props => {
    const { resourcesStatuses } = props;
    const loading = resourcesStatuses.some(resourceStatus =>
      [IDLE, LOADING].includes(resourceStatus),
    );
    return loading ? (
      <Spinner display='block' marginX='auto' marginTop={32} size='md' />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  WithSpinner.propTypes = {
    resourcesStatuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  return WithSpinner;
};
