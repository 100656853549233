import React, { useEffect, useMemo, useState } from 'react';
import { getCurrentCompanyId } from 'lib/auth';
import { useDispatch, useSelector } from 'react-redux';
import { fetchZonesThunk, getZonesByCompany } from 'stores/zones';
import { Box, Flex, Spinner } from '@chakra-ui/react';
import ZonesBar from 'components/ZonesBar';
import { withSpinner } from 'utils/hoc/withSpinner';
import { RESOURCE_STATUS } from 'utils/constants';
import ZonesTable from 'components/ZonesTable';
import { getCurrentUserRole, getCurrentUser } from 'stores/currentUser';
import useMapMonitors from 'hooks/useMapMonitors';
import useMonitorListMap from 'hooks/useMonitorListMap';
import { ZonesEmptyState } from 'components/EmptyState/emptyStates';

const WithSpinnerZonesTable = withSpinner(ZonesTable);

const ZonesContainer = () => {
  const dispatch = useDispatch();
  const currentCompanyId = getCurrentCompanyId();
  const [search, setSearch] = useState('');
  const [alarmFilter, setAlarmFilter] = useState('');
  const [isInMapMode, setIsInMapMode] = useState(false);
  const [firstLoading, setFirstLoading] = useState(true);
  const { items, resourceStatus, alarmsCount } = useSelector(getZonesByCompany);
  const mapMonitors = useMapMonitors({
    isInMapMode,
    currentCompanyId,
    zones: items,
    ...(alarmFilter !== '' && { alarmFilter }),
  });
  const monitorsWithCoordinates = useMemo(() => {
    return mapMonitors?.items?.filter(
      monitor => monitor.longitude && monitor.latitude,
    );
  }, [mapMonitors.items]);
  const mapContainer = useMonitorListMap(monitorsWithCoordinates, isInMapMode);

  useEffect(() => {
    dispatch(
      fetchZonesThunk({
        companyId: currentCompanyId,
        search,
        alarm: alarmFilter,
      }),
    );
  }, [alarmFilter, currentCompanyId, dispatch, search, isInMapMode]);

  const currentUserRole = useSelector(getCurrentUserRole);
  const { isSubTenant } = useSelector(getCurrentUser);

  if (
    [RESOURCE_STATUS.IDLE, RESOURCE_STATUS.LOADING].includes(resourceStatus) &&
    firstLoading
  ) {
    return <Spinner display='block' marginX='auto' marginY={32} />;
  }

  return (
    <Flex direction='column' p='6' height='100%'>
      <Box shadow='md' rounded='lg' bg='white'>
        <ZonesBar
          zones={items}
          currentUserRole={currentUserRole}
          setSearch={data => {
            setSearch(data);
            setFirstLoading(false);
          }}
          alarmsCount={{
            value: alarmsCount?.alarmTypesCount,
            resourceStatus,
          }}
          alarmFilter={alarmFilter}
          submitAlarmFilter={data => {
            setAlarmFilter(data);
            setFirstLoading(false);
          }}
          handleMapModeChange={() => {
            setIsInMapMode(!isInMapMode);
            setFirstLoading(false);
          }}
        />
      </Box>
      {items.length === 0 && resourceStatus === RESOURCE_STATUS.READY ? (
        <Box height='500px'>
          <ZonesEmptyState />
        </Box>
      ) : (
        <WithSpinnerZonesTable
          mapContainer={mapContainer}
          isInMapMode={isInMapMode}
          resourcesStatuses={[
            resourceStatus,
            isInMapMode && mapMonitors?.resourceStatus,
          ]}
          zones={items}
          isModalDisplay={false}
          currentUserRole={currentUserRole}
          isSubTenant={isSubTenant}
          alarm={alarmFilter}
          monitorsWithCoordinates={monitorsWithCoordinates}
        />
      )}
    </Flex>
  );
};

export default ZonesContainer;
