import React from 'react';
import {
  Text,
  Button,
  useDisclosure,
  Flex,
  Box,
  Switch,
} from '@chakra-ui/react';
import { tType } from 'types';
import capitalize from 'lodash.capitalize';
import i18n from 'lib/i18n';
import ZoneCreation from 'containers/Zones/ZoneCreation';
import { ROLES } from 'utils/constants';
import PropTypes from 'prop-types';
import SearchBar from 'components/SearchBar/SearchBar';
import SearchFilters from 'components/MonitorsTable/SearchFilters';

const { withTranslation } = i18n;

const ZonesBar = ({
  t,
  currentUserRole,
  setSearch,
  alarmsCount,
  alarmFilter,
  submitAlarmFilter,
  handleMapModeChange,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleResetFilters = () => {
    handleResetAlarmFilter();
    handleResetSearchBar();
  };

  const handleResetAlarmFilter = () => {
    submitAlarmFilter('');
  };

  const handleResetSearchBar = () => {
    const searchBarValue = document.getElementById('search-bar');
    searchBarValue.value = '';
    setSearch('');
  };

  return (
    <Flex direction='column' p='8'>
      <Flex alignItems='center' justifyContent='space-between'>
        <Text variant='heading4xl'>{capitalize(t('zones_page.zones'))}</Text>
        <Flex w='50%' ml='auto' alignItems='center' justifyContent='flex-end'>
          <Flex alignItems='center'>
            <Text variant='headingMd' mr='4'>
              {capitalize(t('monitors_table.switch_to_map'))}
            </Text>
            <Switch
              mt='1.5'
              mr='4'
              color='alpha.500'
              onChange={handleMapModeChange}
            />
          </Flex>
          <Box w='50%' minW='15rem'>
            <SearchBar
              onChange={setSearch}
              placeholder={t('zones_page.search_zone')}
            />
          </Box>
        </Flex>
        <ZoneCreation isOpen={isOpen} close={onClose} />
      </Flex>
      <Flex my='6'>
        <Box width='80%'>
          <SearchFilters
            alarmsCount={alarmsCount}
            alarmFilter={alarmFilter}
            submitAlarmFilter={submitAlarmFilter}
          />
        </Box>
      </Flex>
      <Flex mt='2px'>
        <Box>
          <Button variant='outline' mr='2' onClick={handleResetFilters}>
            {capitalize(t('zones_page.clear_all_filters'))}
          </Button>
          {currentUserRole !== ROLES.commonUser && (
            <Button onClick={onOpen}>
              {capitalize(t('zones_page.create_new_zone'))}
            </Button>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

ZonesBar.propTypes = {
  t: tType,
  currentUserRole: PropTypes.string,
  setSearch: PropTypes.func,
  alarmsCount: PropTypes.arrayOf(PropTypes.shape()),
  alarmFilter: PropTypes.string,
  submitAlarmFilter: PropTypes.func,
  handleMapModeChange: PropTypes.func,
};

export default withTranslation()(ZonesBar);
