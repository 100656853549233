import React, { useEffect } from 'react';
import { ProtectedRoute } from 'lib/protected-route';
import MainLayout from 'layouts/Main';
import MonitorsTable from 'components/MonitorsTable';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'stores/currentUser';
import { ROLES } from 'utils/constants';
import ZonesContainer from 'containers/Zones';
import i18n from 'lib/i18n';

const HomePage = () => {
  const currentUser = useSelector(getCurrentUser);

  const { role, locale } = currentUser || {};

  useEffect(() => {
    if (currentUser && locale) {
      i18n.i18n.changeLanguage(locale);
    }
  }, [currentUser, locale]);

  const handleHomePageDisplay = () => {
    if (role === ROLES.commonUser) {
      return <MonitorsTable />;
    }
    return <ZonesContainer />;
  };

  return <MainLayout>{handleHomePageDisplay()}</MainLayout>;
};

export default ProtectedRoute(HomePage);
